import React, { useCallback, useEffect, useState } from "react";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";


import { getAnalytics, logEvent } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";

import { Authenticator, CMSView, FirebaseCMSApp, FirestoreIndexesBuilder } from "firecms";
// import { useDataEnhancementPlugin } from "@firecms/data_enhancement";

import { IconButton, Tooltip } from "@mui/material";
import { GitHub } from "@mui/icons-material";

import { firebaseConfig } from "../firebase_config";
import { ExampleCMSView } from "./ExampleCMSView";
import logo from "./images/metrodora.png";

// import { textSearchController } from "./text_search";
import { textSearchController } from "./text_index";

import { customCollectionOverrideHandler } from "./collections/custom_collection_resolver";
import { CustomLoginView } from "./CustomLoginView";
import CustomColorTextField from "./custom_field/CustomColorTextField";
import { FirebaseApp } from "firebase/app";

import { productsCollection } from "./collections/products_collection";
import { leadsCollection } from "./collections/leads_collection";
import { autoassignmentsCollection } from "./collections/autoassignments_collection";
import { centros as centrosEnum } from "./collections/enums";
import { requestsCollection } from "./collections/requests_collection";
import { odooCollection } from "./collections/odoo_collection";

function App() {
  const onFirebaseInit = (config: object, app: FirebaseApp) => {
    // Just calling analytics enables screen tracking
    // getAnalytics(app);
  };
  const [collections, setCollections] = useState([]);

  const allCollections = [{
    roles: ['all'],
    value: productsCollection
  }, {
    roles: ['fisiofocus', 'jurisalud'],
    dynamic: true,
    value: leadsCollection
  }, {
    roles: ['isep'],
    value: odooCollection
  }
  // {
  //   roles: ['all'],
  //   value: requestsCollection
  // }
  ]
    
  const getCollections = (user) => {
    if (user.roles.includes("all-products")) {
      return [
        productsCollection
      ];
    }
    if (user.roles.includes("all")) {
      return [
        productsCollection,
        leadsCollection,
        autoassignmentsCollection,
        requestsCollection,
        odooCollection
      ];
    }
    return allCollections.filter(collection => {
      if (collection.roles.includes("all")) {
        return true;
      }
      return collection.roles.some(role => user.roles.includes(role))
    }).map(collection => {
      if (collection.dynamic) {
        return user.roles.map(rol => {
          const _collection = {
            ...collection,
            value: { ...collection.value }
          }
          _collection.value.path = `zoho-${rol}`
          _collection.value.name = `Zoho ${rol}`
          console.log({ _collection })
          return _collection.value
        })
      }
      return collection.value
    }).flat()
  }

  const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({
    user,
    authController
  }) => {

    if (user?.email?.includes("flanders")) {
      throw Error("Stupid Flanders!");
    }

    const users = [
      {
        "email": "santiagomtzdelariva@gmail.com",
        "roles": ["all"]
      },
      {
        "email": "robinchogiles@gmail.com",
        "roles": ["saius", "isep", "ciep"]
      },
      {
        "email": "aarcher@metrodoraeducation.com",
        "roles": ["all-products"]
      },
      {
        "email": "jprieto@metrodoraeducation.com",
        "roles": [
          "all"
        ]
      },
      {
        "email": "csantalo@metrodoraeducation.com",
        "roles": [
          "all"
        ]
      },
      {
        "email": "cgoberna@metrodoraeducation.com",
        "roles": [
          "cesif"
        ]
      },
      {
        "email": "jcaballero@metrodoraeducation.com",
        "roles": [
          "cesif",
          "saius",
          "isep"
        ]
      },
      {
        "email": "lap@cesif.es",
        "roles": [
          "cesif"
        ]
      },
      {
        "email": "rmonteagudo@isep.es",
        "roles": [
          "isep",
          "saius"
        ]
      },
      {
        "email": "o.cativiela@fpoceano.com",
        "roles": [
          "oa"
        ]
      },
      {
        "email": "admisiones@fisiofocus.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "amartin@metrodoraeducation.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "iortiz@metrodoraeducation.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "rcano@metrodoraeducation.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "imoreno@metrodoraeducation.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "pfreire@metrodoraeducation.com",
        "roles": [
          "fisiofocus"
        ]
      },
      {
        "email": "jurisalud@jurisalud.com",
        "roles": [
          "jurisalud"
        ]
      },
      {
        "email": "info@jurisalud.com",
        "roles": [
          "jurisalud"
        ]
      },
      {
        "email": "direccion@jurisalud.com",
        "roles": [
          "jurisalud"
        ]
      },
      {
        "email": "agg@cesif.es",
        "roles": ["cesif"]
      },
      {
        "email": "jgarcia@metrodoraeducation.com",
        "roles": ["saius", "isep", "ciep"]
      },
      {
        "email": "jlmunoz@metrodoraeducation.com",
        "roles": ["fisiofocus"]
      },
      {
        "email": "aarcher@metrodoraeducation.com",
        "roles": ["all"]
      },
      // isep
      {
        "email": "jgarcia@metrodoraeducation.com",
        "roles": ["isep"]
      },
      {
        "email": "marroyo@isep.es",
        "roles": ["isep"]
      },
      {
        "email": "rmonmany@isep.es",
        "roles": ["isep"]
      },
      {
        "email": "rgallego@metrodoraeducation.com",
        "roles": ["cesif"]
      }
    ]

    // from user@fp.metrodoraeducation.com extract "fp.metrodoraeducation"
    const domain: string = user?.email?.split("@")[1].replace('.com', '') || 'admin';
    const centros: any = {
      keys: ["MetrodoraFP", "Cesif", "Saius", "Universanidad", "oa", "isep", "fisiofocus", "jursialud"].map(i => i.toLowerCase()),
      values: {
        "metrodorafp": "MetrodoraFP",
        "cesif": "Cesif",
        "saius": "Saius",
        "universanidad": "Universanidad",
        "oa": "oa",
        "isep": "isep",
        "fisiofocus": "Fisiofocus",
        "jurisalud": "jurisalud",
        "euses": "euses",
        "ciep": "ciep"
      }
    }
    const leadsCentros: any = {
      values: {
        "metrodorafp": "MetrodoraFP",
        "cesif": "Cesif",
        "saius": "Saius",
        "universanidad": "Universanidad",
        "oa": "oa",
        "isep": "isep",
        "fisiofocus": "fisiofocus",
        "jurisalud": "jurisalud"
      }
    }
    const verticals: any = {
      keys: ["es.metrodoraeducation", "fp.metrodoraeducation"].map(i => i.toLowerCase()),
      values: {
        "es.metrodoraeducation": "es",
        "fp.metrodoraeducation": "fp",
      }
    }
    if (verticals.keys.includes(domain)) {
      productsCollection.forceFilter = {
        vertical: ["==", verticals.values[domain]]
      }
    }
    const currentEmail = user?.email
    const userFound = users.find(user => user.email === currentEmail)
    if (userFound) {
      const roles = (userFound || {}).roles || [];
      const rolesMap = roles.filter(rol => centros.values[rol])
      if (roles.length && rolesMap.length) {
        productsCollection.forceFilter = {
          centro: ["in", roles.map(rol => centros.values[rol])]
        }
        // update sedes == roles
        roles.map(rol => centrosEnum[centros.values[rol]] = centros.values[rol])
      }
    }

    const collectionsFiltered: any = getCollections({
      email: user?.email,
      roles: (users.find(u => u.email === currentEmail) || {}).roles || []
    });
    setCollections(collectionsFiltered);

    // This is an example of retrieving async data related to the user
    // and storing it in the controller's extra field
    const idTokenResult = await user?.getIdTokenResult();
    const userIsAdmin = idTokenResult?.claims.admin || currentEmail?.endsWith("@camberi.com");
    authController.setExtra({
      roles: {
        admin: userIsAdmin
      }
    });

    if (userFound && userFound.roles.length) {
      return true;
    }
    
    return false;
  }, []);

  const onAnalyticsEvent = useCallback((event: string, data?: object) => {
    const analytics = getAnalytics();
    logEvent(analytics, event, data);
  }, []);

  // const dataEnhancementPlugin = useDataEnhancementPlugin({
  //   getConfigForPath: ({ path }) => {
  //     if (process.env.NODE_ENV !== "production")
  //       return true;
  //     if (path === "books")
  //       return true;
  //     return false;
  //   }
  // });

  // useEffect(() => {
  //   productsCollection.forceFilter = {
  //     centro: ["==", "MetrodoraFP"]
  //   }
  // }, []);

  return <FirebaseCMSApp
    name={"Metrodora"}
    // appCheckOptions={appCheckOptions}
    authentication={myAuthenticator}
    allowSkipLogin={false}
    plugins={[]}
    signInOptions={["password"]}
    textSearchController={textSearchController}
    logo={logo}
    collections={collections}
    // views={customViews}
    collectionOverrideHandler={customCollectionOverrideHandler}
    firebaseConfig={firebaseConfig}
    onFirebaseInit={onFirebaseInit}
    // toolbarExtraWidget={githubLink}
    LoginView={CustomLoginView}
  />;

}

export default App;
